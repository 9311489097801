<template>
  <div class="main-content">
    <div class="section post-section">
      <div class="row">
        <div class="full">
          <div class="pst-block">
            <div class="pst-block-main">
              <div class="post-content">
                <article>
                  <div class="post-main-img">
                    <figure v-if="$store.state.poster.big_img_sections.length">
                      <img :src="$store.state.poster.big_img_sections[0].get_img.media_server_url + $store.state.poster.big_img_sections[0].get_img.url" :alt="$store.state.poster.title" class="adaptive" style="height:582px;width:100%;object-fit:cover"/>
                    </figure>
                    <figure v-else>
                      <img :src="$store.state.system_data.base_url + '/img/placeholder.png'" :alt="$store.state.poster.title" class="adaptive" style="height:582px;width:100%;object-fit:cover"/>
                    </figure>
                    <div class="post-main-overlay">
                      <div class="post-main-data">
                        <h2 class="title-21">{{ $store.state.poster.title }}</h2>
                        <div class="meta-tp-5">
                          <div class="date-tp-4"><i class="li_clock"></i>{{ $store.state.poster.updated_at | human_date }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-html="$store.state.poster.description"></div>
                  <div class="ya-share2-wrapper">
                    <p>Поделиться: </p>
                    <yandex-share :services="['vkontakte','facebook','odnoklassniki','twitter','whatsapp']" />
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YandexShare from '@cookieseater/vue-yandex-share';

export default {
  name: 'Poster',
  async asyncData (context) {
    await context.store.dispatch('posterPage', context.route.params.id);
  },
  metaInfo () {
    return this.$seo(this.$store.state.poster.title);
  },
  components: { YandexShare }
};
</script>
